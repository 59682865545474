import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Reactmarkdown from "react-markdown"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import HeaderKontakt from "../../components/header_kontakt"

const RegularPage = ({ data }) => {
  const qry = data.allStrapiDatenschutz.edges[0].node
  const heroImage = qry.picture.localFile.childImageSharp.fluid

  return (
    <Layout>
      <SEO title="Datenschutz" />
      <HeaderKontakt />
      <Img fluid={heroImage} className="heroimage" />
      <Reactmarkdown>{qry.content}</Reactmarkdown>
    </Layout>
  )
}

export default RegularPage

export const query = graphql`
  query DatenschutzQuery {
    allStrapiDatenschutz {
      edges {
        node {
          content
          picture {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
